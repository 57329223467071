/*
 * 업무구분: 고객
 * 화 면 명: MSPCM170M
 * 화면설명: 정보활용동의현황
 * 작 성 일: 2023.02.13
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="정보활용동의현황" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM171P()" @on-scroll-bottom="fn_LoadingData()" :topButton="true">
    <template #frame-header-fixed>
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData170M !== {}" @click="fn_OpenMSPCM171P">
            <span class="txt">{{pOrgData170M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData170M !== {}" @click="fn_OpenMSPCM171P">
            <span class="txt">{{pOrgData170M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData170M !== {}" @click="fn_OpenMSPCM171P">
            <span class="txt">{{pOrgData170M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData170M !== {}" @click="fn_OpenMSPCM171P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData170M.cnslt.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM171P">
            <span class="tit">고객</span>
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[1] !== ''" @click="fn_OpenMSPCM171P">
            <span class="tit">승인상태</span>
            <span class="txt">{{lv_ReturnStr[1]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[2] !== ''" @click="fn_OpenMSPCM171P">
            <span class="tit">동의경로</span>
            <span class="txt">{{lv_ReturnStr[2]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[3] !== ''" @click="fn_OpenMSPCM171P">
            <span class="tit">동의항목</span>
            <span class="txt">{{lv_ReturnStr[3]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[4] !== '' && lv_ReturnStr[5] !== ''" @click="fn_OpenMSPCM171P">
            <span class="tit">조회기간</span>
            <span class="txt">{{lv_ReturnStr[4] + ' ~ ' + lv_ReturnStr[5]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView" size="small" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{totCnt}}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container v-if="!isEmptyView" direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list type-2">
        <mo-list :list-data="cMInfoPrcusCnsntListVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)" expandable prevent-expand>
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItem(item)" :disabled="checkDisabled" :ref="`chk${index}`"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <!-- 고객명 -->
                  <span class="name txtSkip" @click.stop="fn_OpenCustInfoBs(item)">{{item.custNm}}</span>
                  <!-- 동의종류 -->
                  <mo-badge v-if="item.infoPrcusCnsntTypCd === '19'" class="badge-sample-badge lightblue sm" :text="item.infoPrcusCnsntTypCdNm" shape="status">마케팅동의</mo-badge> 
                  <mo-badge v-else class="badge-sample-badge lightgreen sm" :text="item.infoPrcusCnsntTypCdNm" shape="status">필수컨설팅</mo-badge>
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk7 fs14rem mr10">승인일</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.avlStrYmd)}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk7 fs14rem mr10">종료일</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.avlEndYmd)}}</span>
                </div>
              </div>
              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min80">주고객</span><span class="ml10">{{item.mnCustNm}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min80">관계</span><span class="ml10">{{item.mnCustRltnCdNm}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min80">동의경로</span><span class="ml10">{{item.infoPrcusCnsntPathCdNm}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline crTy-bk7 fs14rem mr10 min80">승인자</span><span class="ml10">{{item.aprvrNm}}</span>
                  </div>
                  <div v-if="item.infoPrcusCnsntTypCd === '19'">
                    <div class="contents-row">
                      <span class="dsInline crTy-bk7 fs14rem mr10 min80">연락방식</span><span class="ml10">{{item.mktCase}}</span>
                    </div>
                    <div class="contents-row mt20">
                      <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round crTy-bk4 bdTy4 white sm" @click.stop="fn_OpenMSPCM172P(item.infoPrcusCnsntId)">마케팅동의 이력</mo-button>
                    </div>
                  </div>
                </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>
        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
        <div :class="cMInfoPrcusCnsntListVO.length > 0 ? 'ns-height40' : ''"></div>
      </ur-box-container>
    </ur-box-container>

    <!-- NoData 영역  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPCM171P from '@/ui/cm/MSPCM171P' // 조회조건팝업
import MSPCM172P from '@/ui/cm/MSPCM172P' // 마케팅동의 이력
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM170M',
  // 현재 화면 ID
  screenId: 'MSPCM170M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM171P,
    MSPCM172P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      isSearched: false,  // 검색 실행 여부
      isInit: false, // 검색 초기화 여부
      isMngr: false, // 지점장 여부
      isFirst: true, // 최초조회시 로그인정보를 위한 구분값
      pOrgData170M: {}, // 최근 조직 조회 데이터
    
      // 제어 변수
      isOneOrMoreCheck: false, // 전체선택
      isCustCardExist: true, // 채널아이디 여부
      checkDisabled: false, // 체크박스 선택가능여부

      // 팝업 연동
      popup171: {}, // MSPCM171P 전체고객 목록 검색
      popup172: {}, // MSPCM172P 마케팅동의 이력
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      
      // 통신 변수
      custRltnList: [], // 관계 정보
      nowDate: this.$bizUtil.getDateToString(new Date(), ''),
      cMInfoPrcusCnsntListVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 조회 서비스호출 Obj
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호
        cnsltNo: '', // 컨설턴트번호
        cnsltNm: '', // 컨설턴트명
        custNm: '', // 고객명
        infoPrcusCnsntTypCd: '', // 정보활용동의유형코드
        infoPrcusCnsntStatCd: '', // 정보활용동의상태코드
        infoPrcusCnsntPathCd: '', // 정보활용동의경로코드
        inqrStrYmd: '', // 조회시작일자
        inqrEndYmd: '', // 조회종료일자
        inqrScCd: '' // 조회구분코드
      },
      totCnt: 0, // 누적 총 조회건수
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      startDate: this.$bizUtil.getFirstDayOfNowMonth('String'), // 조회 시작일 : 이번달1일
      endDate: this.$bizUtil.getLastDayOfNowMonth('String'), // 조회 종료일 : 이번달말일

      // 더보기 key
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}],
      stndKeyVal: 'start',
      pageRowCnt: '20', // 페이지 카운트

      // 팝업 변수
      lv_inputParamS: {}, // 조회 Obj
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.cMInfoPrcusCnsntListVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cMInfoPrcusCnsntListVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData170M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cMInfoPrcusCnsntListVO.length && this.cMInfoPrcusCnsntListVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    let lv_Vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 조회
    lv_Vm.fn_Submit()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData170M = {}  // 조직 조회 데이터
          this.isSearched = false // 검색이력
          this.isInit = true
          this.isFirst = true
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.cMInfoPrcusCnsntListVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.cMInfoPrcusCnsntListVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.cMInfoPrcusCnsntListVO[n].checked = true
            } else {
              lv_Vm.cMInfoPrcusCnsntListVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItem()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItem
     * 설명       : 체크 핸들러
     ******************************************************************************/
    fn_CheckSelectedItem (item) {
      // 고객 체크 시 BottomSheet
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData170M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData170M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      this.cMInfoPrcusCnsntListVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.fn_DataSet() // 조회 파라미터 세팅

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      // 지점장 조회시 구분
      if (!_.isEmpty(this.pOrgData170M) && this.isMngr === true) {
        this.lv_inputParam.cnsltNo  = this.pOrgData170M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParam.cnsltNo  = this.lv_cnsltNo // 컨설턴트 번호
      }

      // 초회 상세조회조건 지정
      if (this.isFirst) {
        this.pOrgData170M = {}  // 상세조회조건
        this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
        this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
        this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
        this.lv_inputParam.hofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호
        this.lv_inputParam.custNm = '' // 고객명
        this.lv_inputParam.infoPrcusCnsntTypCd = '' // '19' // 정보활용동의유형코드
        this.lv_inputParam.infoPrcusCnsntStatCd = '' // '02' // 정보활용동의상태코드
        this.lv_inputParam.infoPrcusCnsntPathCd = '' // '03' // 정보활용동의경로코드
        this.lv_inputParam.inqrScCd = '1' // 조회구분코드

        if (this.startDate.indexOf('-') >= 0) {
          this.lv_inputParam.inqrStrYmd = this.startDate.replace(/-/gi, '').substring(0, 8) // 조회시작일자
        } else {
          this.lv_inputParam.inqrStrYmd = this.startDate
        }

        if (this.endDate.indexOf('-') >= 0) {
          this.lv_inputParam.inqrEndYmd = this.endDate.replace(/-/gi, '').substring(0, 8) // 조회종료일자
        } else {
          this.lv_inputParam.inqrEndYmd = this.endDate
        }

        if (this.startDate === '' || this.startDate === null || this.startDate === undefined) {
          this.lv_inputParam.inqrStrYmd = this.$bizUtil.getFirstDayOfNowMonth('String')
        } 
        if (this.endDate === '' || this.endDate === null || this.endDate === undefined) {
          this.lv_inputParam.inqrEndYmd = this.$bizUtil.getLastDayOfNowMonth('String')
        } 
      }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(정보활용동의현황 목록 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM11S4'// 정보활용동의현황 조회
      const trnstId = 'txTSSCM49S3'// 정보활용동의현황 조회
      const auth = 'S'
      const lv_Vm = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.cminfoPrcusCnsntListVO

            if (responseList !== null) {
              for (let i = 0; i < responseList.length; i++) {
                let mktStr = ''
                responseList[i].checked = false
                
                if ( responseList[i].infoPrcusCnsntTypCdNm === '필수컨설팅동의') {
                  responseList[i].infoPrcusCnsntTypCdNm = '필수컨설팅'
                }

                if (responseList[i].mktCnvsMthTelYn.trim() === 'Y') {
                  mktStr += '전화'
                }
                if (responseList[i].mktCnvsMthSmsYn.trim() === 'Y') {
                  if (mktStr.length === 0) {
                    mktStr = '문자'
                  } else {
                    mktStr += ', 문자'
                  }
                }
                if (responseList[i].mktCnvsMthEmailYn.trim() === 'Y') {
                  if (mktStr.length === 0) {
                    mktStr = '이메일'
                  } else {
                    mktStr += ', 이메일'
                  }
                }
                if (responseList[i].mktCnvsMthPstYn.trim() === 'Y') {
                  if (mktStr.length === 0) {
                    mktStr = '우편'
                  } else {
                    mktStr += ', 우편'
                  }
                }
                responseList[i].mktCase = mktStr
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cMInfoPrcusCnsntListVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cMInfoPrcusCnsntListVO = responseList
              }

              if (responseList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cMInfoPrcusCnsntListVO.length
              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      if (!this.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false
        }

        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('PART')

        this.fn_GetService()
      }
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM171P
     * 설명: 정보활용동의 상세조회 팝업 호출
     *********************************************************/
    fn_OpenMSPCM171P () {
      this.popup171 = this.$bottomModal.open(MSPCM171P, {
        properties: {
          pPage: 'MSPCM170M',
          pOrgData: this.pOrgData170M, // 최근 조직 조회 데이터
          pIsMngr: this.isMngr, // 지점장 여부
          pSearchCustNm: this.lv_inputParam.custNm, // 고객명
          pStartDate: this.startDate, // 조회시작일자
          pEndDate: this.endDate, // 조회종료일자
          pInqrScCd : this.lv_inputParam.inqrScCd,  // 조회기간
          pInfoPrcusCnsntTypCd : this.lv_inputParam.infoPrcusCnsntTypCd,  // 동의항목
          pInfoPrcusCnsntStatCd : this.lv_inputParam.infoPrcusCnsntStatCd,// 승인상태
          pInfoPrcusCnsntPathCd : this.lv_inputParam.infoPrcusCnsntPathCd // 동의경로
        },
        listeners: {
          onPopupSearch: (pData) => {
            this.isFirst = false

            // 모달 닫기
            this.$bottomModal.close(this.popup171)

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData170M = pData.rtnData.pOrgData
            if (this.isMngr) {
              this.lv_inputParam.hofOrgNo = this.pOrgData170M.hofOrg.key
              this.lv_inputParam.dofOrgNo = this.pOrgData170M.dofOrg.key
              this.lv_inputParam.fofOrgNo = this.pOrgData170M.fofOrg.key
            }
            // 나머지 조회 조건 데이터 세팅
            this.lv_inputParam.custNm = pData.rtnData.lv_SearchCustNm // 고객명
            this.lv_inputParam.inqrScCd = pData.rtnData.inqrScCd  //조회기간
            this.startDate = pData.rtnData.startDate // 조회시작일자
            if (this.startDate.indexOf('-') >= 0) {
              this.lv_inputParam.inqrStrYmd = this.startDate.replace(/-/gi, '').substring(0, 8) // 조회시작일자
            } 
            this.endDate = pData.rtnData.endDate // 조회종료일자
            if (this.endDate.indexOf('-') >= 0) {
              this.lv_inputParam.inqrEndYmd = this.endDate.replace(/-/gi, '').substring(0, 8) // 조회종료일자
            } 
            this.lv_inputParam.infoPrcusCnsntTypCd = pData.rtnData.infoPrcusCnsntTypCd  // 동의항목
            this.lv_inputParam.infoPrcusCnsntStatCd = pData.rtnData.infoPrcusCnsntStatCd  // 승인상태
            this.lv_inputParam.infoPrcusCnsntPathCd = pData.rtnData.infoPrcusCnsntPathCd  //동의경로

            // 검색 실행 여부
            this.isSearched = true
            // 검색 초기화 여부
            this.isInit = pData.isInit
            if (this.isInit) this.isSearched = false
            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt

            // 정보활용동의현황 조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM172P
     * 설명       : 마케팅동의 이력 팝업
     ******************************************************************************/
    fn_OpenMSPCM172P (infoPrcusCnsntId) {
      this.popup172 = this.$bottomModal.open(MSPCM172P, {
        properties: {
          infoPrcusCnsntId: infoPrcusCnsntId,
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popup172)
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      if(this.pOrgData170M.cnslt === undefined || this.pOrgData170M.cnslt === null || this.pOrgData170M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData170M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM170M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData170M = {}  // 조직 조회 데이터
                this.isSearched = false // 검색이력
                this.isInit = true
                this.isFirst = true

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
}
</script>
