/*
 * 업무구분: 고객
 * 화 면 명: MSPCM171P
 * 화면설명: 정보활용동의 상세조회
 * 접근권한: 
 * 작 성 일: 2023.02.13
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="고객검색" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container v-if="lv_IsMngr" direction="column" alignV="start" class="ns-sch-area">
          <SearchCondOrgCM ref="refSearchOrg" v-model="lv_OrgSearchData" :isFofType="1" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultPM"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field class="mb0" ref="error" type="none" v-model="lv_SearchCustNm" clearable underline placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">승인상태</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="infoPrcusCnsntStatCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in infoPrcusCnsntStatCds" :value="item.value" :key="idx" v-show="idx < 2">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="infoPrcusCnsntStatCd" class="chip-type-wrap mt10">
              <mo-segment-button v-for="(item, idx) in infoPrcusCnsntStatCds" :value="item.value" :key="idx" v-show="idx > 1">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">동의경로</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="infoPrcusCnsntPathCds" v-model="infoPrcusCnsntPathCd" underline class="ns-dropdown-sheet select-resposive" bottom-title="동의경로" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">동의항목</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="infoPrcusCnsntTypCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in infoPrcusCnsntTypCds" :value="item.value" :key="idx" v-show="idx < 2">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="infoPrcusCnsntTypCd" class="chip-type-wrap mt10">
              <mo-segment-button v-for="(item, idx) in infoPrcusCnsntTypCds" :value="item.value" :key="idx" v-show="idx > 1">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">조회기간</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="inqrScCd" class="chip-type-wrap">
              <mo-segment-button value="1">{{ inqrScCds[0].label }}</mo-segment-button>
              <mo-segment-button value="2">{{ inqrScCds[1].label }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area mb80 pt10">
          <div class="add-date">
            <mo-date-picker bottom v-model="startDate" placeholder="선택일" class="ns-date-picker" />
            <span class="tilde"></span>
            <mo-date-picker bottom v-model="endDate" placeholder="선택일" class="ns-date-picker" />
          </div>
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ValidationCheck">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>

    <!-- Toast -->
    <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>
  </ur-page-container>
</template>

<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import moment from 'moment'
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  name: 'MSPCM171P',
  screenId: 'MSPCM171P',
  components: {
    SearchCondOrgCM,
  },
  props: {
    pIsMngr: Boolean, // 조직combox 권한자여부
    pOrgData: {
      type: Object,
      default: {}
    },
    pSearchCustNm: String, // 계약자명
    pStartDate: String, // 조회시작일자
    pEndDate: String, // 조회종료일자
    pInqrScCd: String,
    pInfoPrcusCnsntTypCd: String,
    pInfoPrcusCnsntStatCd: String,
    pInfoPrcusCnsntPathCd: String
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    const lv_Vm = this

    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')

    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_IsMngr = this.$props.pIsMngr // 관리자여부
    this.lv_OrgSearchData = _.cloneDeep(this.$props.pOrgData) // 조직정보
    this.lv_SearchCustNm = _.cloneDeep(this.$props.pSearchCustNm) // 계약자명
    this.startDate = this.pStartDate
    this.endDate = this.pEndDate
    this.inqrScCd = this.pInqrScCd   // 조회기간
    
    // 전체 key값 변경 '' => 99
    if (this.pInfoPrcusCnsntTypCd === '') this.infoPrcusCnsntTypCd = '99'
    else this.infoPrcusCnsntTypCd = this.pInfoPrcusCnsntTypCd   // 동의항목

    if (this.pInfoPrcusCnsntStatCd === '') this.infoPrcusCnsntStatCd = '99'
    else this.infoPrcusCnsntStatCd = this.pInfoPrcusCnsntStatCd // 승인상태

    if (this.pInfoPrcusCnsntPathCd === '') this.infoPrcusCnsntPathCd = '99'
    else this.infoPrcusCnsntPathCd = this.pInfoPrcusCnsntPathCd // 승인상태

    // 공통코드 조회
    let codeList1 = []
    let codeList2 = []
    let codeList3 = []

    window.vue.getInstance('vue').$commonUtil.selListMassEACommCd([
      'ZA_INFO_PRCUS_CNSNT_TYP_CD', // 정보활용동의유형코드 (10:보험상담 11:전환설계 12:실손조회 13:비교안내문 14:언더가입설계 17:필수컨설팅동의 18:필수컨설팅/마케팅동의 19:마케팅동의 20:정보활용동의/다이렉트)'ZA_ELTRN_MAL_DM_CD', // 이메일도메인 코드정보
      'ZA_INFO_PRCUS_CNSNT_STAT_CD', // 정보활용동의상태코드,승인구분코드 (01:신청 02:승인 03:승인취소)'ZA_CELPH_KNB_CD', // 휴대전화국번 코드정보
      'ZA_INFO_PRCUS_CNSNT_PATH_CD' // 정보활용동의경로코드 (01:동의서 02:휴대폰 03:신용카드 04:휴대폰사랑On 05:동의서스마트CRM 06: 휴대폰스마트CRM 07:신용카드스마트CRM 08:다이렉트상담신청(삭제!!!!) 09:홈페이지 셀프동의 12:카카오Pay사랑On 16:카카오Pay사랑On미니 (신규) )
    ]).then(function () {
      // 휴대전화 앞자리 정보 세팅
      codeList1 = lv_Vm.getStore('code').getters.getState.get('ZA_INFO_PRCUS_CNSNT_TYP_CD') // 정보활용동의유형코드 (10:보험상담 11:전환설계 12:실손조회 13:비교안내문 14:언더가입설계 17:필수컨설팅동의 18:필수컨설팅/마케팅동의 19:마케팅동의 20:정보활용동의/다이렉트)
      codeList2 = lv_Vm.getStore('code').getters.getState.get('ZA_INFO_PRCUS_CNSNT_STAT_CD') // 정보활용동의상태코드,승인구분코드 (01:신청 02:승인 03:승인취소)
      codeList3 = lv_Vm.getStore('code').getters.getState.get('ZA_INFO_PRCUS_CNSNT_PATH_CD') // 정보활용동의경로코드 (01:동의서 02:휴대폰 03:신용카드 04:휴대폰사랑On 05:동의서스마트CRM 06: 휴대폰스마트CRM 07:신용카드스마트CRM 08:다이렉트상담신청(삭제!!!!) 09:홈페이지 셀프동의 12:카카오Pay사랑On 16:카카오Pay사랑On미니 (신규) )

      if (codeList1 !== undefined && codeList1 !== null && codeList1.length !== 0) {
        if (codeList2 !== undefined && codeList2 !== null && codeList2.length !== 0) {
          if (codeList3 !== undefined && codeList3 !== null && codeList3.length !== 0) {
            lv_Vm.fn_RetrieveUserData_CNTNT(codeList1, codeList2, codeList3)
          }
        }
      }
    })
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  watch: {
    lv_SearchCustNm () {
      let englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.lv_SearchCustNm)) {
        if (englishOrKorean.test(this.lv_SearchCustNm)) {
          this.lv_SearchCustNm = this.lv_SearchCustNm.toUpperCase()
        }
      }
    }
  },

  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      toastMsg: '', // toast 문구

      userInfo: {},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 조회조건 텍스트 배열
      lv_SearchCustNm: '', // 계약자명
      startDate: '', // 조회시작일자
      endDate: '', // 조회종료일자

      // 정보활용동의유형코드
      infoPrcusCnsntTypCds: [],
      infoPrcusCnsntTypCd: '99',
      // 정보활용동의상태코드,승인구분코드
      infoPrcusCnsntStatCds: [],
      infoPrcusCnsntStatCd: '99',
      // 정보활용동의경로코드
      infoPrcusCnsntPathCds: [],
      infoPrcusCnsntPathCd: '99',
      // 조회구분코드
      inqrScCds: [{key: '1', label: '승인일'}, {key: '2', label: '유효시작일'}],
      inqrScCd: '1',

      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보
      lv_IsMngr: false, // 관리자여부
      lv_AlertPop: '' // bottom alert component
    }
  },

  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_RetrieveUserData_CNTNT
     * 설명       : 정보활용동의현황 승인 상태, 동의 경로, 동의 항목 세팅
     ******************************************************************************/
    fn_RetrieveUserData_CNTNT (codeList1, codeList2, codeList3) {
      // 공통코드 데이터 컨트롤
      let infoPrcusCnsntTypCdsTemp = this.$bizUtil.parseSegmentHeadCode(codeList1, '99', '전체')
      let infoPrcusCnsntStatCdsTemp = this.$bizUtil.parseSegmentHeadCode(codeList2, '99', '전체')
      let infoPrcusCnsntPathCdsTemp = this.$bizUtil.parseSegmentHeadCode(codeList3, '99', '전체')

      // 승인 상태
      for (let i in infoPrcusCnsntStatCdsTemp) {
        // key,label 값 UI-DEV 기준에 맞춰 value,text로 수정
        let tempObj = {
          value: infoPrcusCnsntStatCdsTemp[i].key,
          text: infoPrcusCnsntStatCdsTemp[i].label
        }
        this.infoPrcusCnsntStatCds.push(tempObj)
      }

      // 동의 경로
      for (let i = 0; i < infoPrcusCnsntPathCdsTemp.length; i++) { // 다이렉트상담신청 삭제
        if (infoPrcusCnsntPathCdsTemp[i].key !== '08') {
          // key,label 값 UI-DEV 기준에 맞춰 value,text로 수정
          let tempObj = {
            value: infoPrcusCnsntPathCdsTemp[i].key,
            text: infoPrcusCnsntPathCdsTemp[i].label
          }
          this.infoPrcusCnsntPathCds.push(tempObj)
        }
      }

      // 동의 항목
      for (let i = 0; i < infoPrcusCnsntTypCdsTemp.length; i++) { // '전체' 0번째
        if (infoPrcusCnsntTypCdsTemp[i].key.trim() === '99') {
          let tempObj = {
            value: infoPrcusCnsntTypCdsTemp[i].key,
            text: infoPrcusCnsntTypCdsTemp[i].label
          }
          this.infoPrcusCnsntTypCds.push(tempObj)
        }
      }
      for (let i = 0; i < infoPrcusCnsntTypCdsTemp.length; i++) { // '필수건설팅' 1번째
        if (infoPrcusCnsntTypCdsTemp[i].key === '17') {
          let tempObj = {
            value: infoPrcusCnsntTypCdsTemp[i].key,
            text: infoPrcusCnsntTypCdsTemp[i].label
          }
          this.infoPrcusCnsntTypCds.push(tempObj)
        }
      }
      for (let i = 0; i < infoPrcusCnsntTypCdsTemp.length; i++) { // '마케팅동의' 2번째
        if (infoPrcusCnsntTypCdsTemp[i].key === '19') {
          let tempObj = {
            value: infoPrcusCnsntTypCdsTemp[i].key,
            text: infoPrcusCnsntTypCdsTemp[i].label
          }
          this.infoPrcusCnsntTypCds.push(tempObj)
        }
      }
      for (let i = 0; i < infoPrcusCnsntTypCdsTemp.length; i++) { // 필컨/마케팅 제외하고 나머지
        if (infoPrcusCnsntTypCdsTemp[i].key !== '99' && infoPrcusCnsntTypCdsTemp[i].key !== '17' && infoPrcusCnsntTypCdsTemp[i].key !== '18' && infoPrcusCnsntTypCdsTemp[i].key !== '19' && infoPrcusCnsntTypCdsTemp[i].key !== '20') {
          let tempObj = {
            value: infoPrcusCnsntTypCdsTemp[i].key,
            text: infoPrcusCnsntTypCdsTemp[i].label
          }
          this.infoPrcusCnsntTypCds.push(tempObj)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_SlectCnsltResultPM()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultPM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
     * Function명 : fn_ResetBtnClick
     * 설명       : 상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_ResetBtnClick (e) {
      // 조직
      this.lv_OrgSearchData = {}
      this.lv_RtnOrgSearchData = {}
      this.lv_SearchCustNm = '' // 고객명
      this.startDate = this.$bizUtil.getFirstDayOfNowMonth('String'), // 조회 시작일 : 이번달1일
      this.endDate = this.$bizUtil.getLastDayOfNowMonth('String') // 종료일 : 당월 말일
      this.infoPrcusCnsntTypCd = '99'
      this.infoPrcusCnsntStatCd = '99'
      this.infoPrcusCnsntPathCd = '99'
      this.inqrScCd = '1'
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_Search () {
      // 조직정보
      this.lv_ReturnVal.pIsMngr = this.lv_IsMngr // 관리자여부
      this.lv_ReturnVal.pOrgData = _.cloneDeep(this.lv_RtnOrgSearchData)

      this.lv_ReturnVal.lv_SearchCustNm = this.lv_SearchCustNm.trim() // 고객명
      this.lv_ReturnVal.startDate = this.startDate // 조회시작일자
      this.lv_ReturnVal.endDate = this.endDate     // 조회종료일자
      this.lv_ReturnVal.inqrScCd = this.inqrScCd   // 조회구분

      // 전체 key값 변경 99 => ''
      if (this.infoPrcusCnsntTypCd === '99') this.lv_ReturnVal.infoPrcusCnsntTypCd = ''
      else this.lv_ReturnVal.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd   // 동의항목

      if (this.infoPrcusCnsntStatCd === '99') this.lv_ReturnVal.infoPrcusCnsntStatCd = ''
      else this.lv_ReturnVal.infoPrcusCnsntStatCd = this.infoPrcusCnsntStatCd // 승인상태

      if (this.infoPrcusCnsntPathCd === '99') this.lv_ReturnVal.infoPrcusCnsntPathCd = ''
      else this.lv_ReturnVal.infoPrcusCnsntPathCd = this.infoPrcusCnsntPathCd // 동의경로

      // 초기화 여부
      let isInit = false
      if (this.$bizUtil.isEmpty(this.lv_ReturnVal.lv_SearchCustNm) && this.lv_ReturnVal.startDate === this.$bizUtil.getFirstDayOfNowMonth('String')
          && this.lv_ReturnVal.endDate === this.$bizUtil.getLastDayOfNowMonth('String') && this.lv_ReturnVal.inqrScCd === '1'
          && this.lv_ReturnVal.infoPrcusCnsntTypCd === '' && this.lv_ReturnVal.infoPrcusCnsntStatCd === '' && this.lv_ReturnVal.infoPrcusCnsntPathCd === '') {
        if (this.lv_ReturnVal.pIsMngr) {
          if (this.userInfo.userId === this.lv_ReturnVal.pOrgData.cnslt.key) isInit = true
          else isInit = false
        } else isInit = true
      }

      // 조회조건 텍스트 배열
      this.lv_ReturnStr = [] // 초기화
      // 조회조건: 고객명, 승인상태, 동의경로, 동의항목, 조회시작일자, 조회종료일자(인수구분 사용하지 않음)
      this.lv_ReturnStr = [this.lv_ReturnVal.lv_SearchCustNm, this.infoPrcusCnsntStatCds.find(e => e.value === this.infoPrcusCnsntStatCd).text,
                          this.infoPrcusCnsntPathCds.find(e => e.value === this.infoPrcusCnsntPathCd).text, this.infoPrcusCnsntTypCds.find(e => e.value === this.infoPrcusCnsntTypCd).text,
                          this.lv_ReturnVal.startDate, this.lv_ReturnVal.endDate]

      this.$emit('onPopupSearch', {rtnData: this.lv_ReturnVal, srchOpt: this.lv_ReturnStr, isInit: isInit})
    },

    /******************************************************************************
     * Function명 : fn_ValidationCheck
     * 설명       : 상세조회조건 유효성 체크
     ******************************************************************************/
    fn_ValidationCheck () {
      // 날짜 선택 유효성 체크
      if (moment.duration(moment(this.startDate).diff(moment(this.endDate))).asDays() > 0) {
        this.toastMsg = '조회 시작일은 종료일 보다 클 수 없습니다.'
        this.$refs.snackbar.show({ text: this.toastMsg })
        return
      }

      // 지정한 조건으로 상세조회
      this.fn_Search()
    },

    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     ******************************************************************************/
    fn_BottomAlert (title, pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: title,
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    }
  }
}
</script>
