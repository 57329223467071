/*
 * 업무구분: 고객
 * 화 면 명: MSPCM172P
 * 화면설명: 마케팅동의 이력
 * 작 성 일: 2023.02.13
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="마케팅동의 이력" :show-title="true" type="subpage" :topButton="true">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{totCnt}}</strong> 건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="cmmktCnsntHstrListVO">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title fexTy4">
                  <span class="name txtSkip">{{item.custNm}}</span>
                  <mo-badge v-if="item.mktCnsntYn === 'Y'" class="badge-sample-badge green sm" text="승인" shape="status">승인</mo-badge> 
                  <!-- <mo-badge v-if="item.data2 === '2'" class="badge-sample-badge blue sm" text="신청" shape="status">신청</mo-badge>  -->
                  <mo-badge v-else class="badge-sample-badge red sm" text="승인취소" shape="status">승인취소</mo-badge> 
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{fn_Yymmdd(item.dob)}}</span>
                  <span v-if="item.mktCnsntYn === 'Y'"><em>|</em><span class="crTy-bk7 fs14rem mr10">승인일시</span><span class="crTy-bk1">{{$bizUtil.dateYyMmDdHhMmFormat(item.aprvDtm)}}</span></span>
                </div>
                <div class="list-item__contents__info">
                  <span><span class="crTy-bk7 fs14rem mr10">시작일</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.avlStrYmd)}}</span></span>
                  <em>|</em>
                  <span><span class="crTy-bk7 fs14rem mr10">종료일</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.avlEndYmd)}}</span></span>
                </div>
                <div class="list-item__contents__info">
                  <span><span class="crTy-bk7 fs14rem mr10">처리경로</span><span class="crTy-bk1">{{item.custFstInptPathCdNm}}</span></span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div :class="cmmktCnsntHstrListVO.length > 0 ? 'ns-height70' : ''"></div>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmClick">확인</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM172P",
    screenId: "MSPCM172P",

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        privateId: '',
        // 누적 총 조회건수
        totCnt: 0,
        // 서비스호출 파라미터
        lv_inputParam: {
          infoPrcusCnsntId: '' // 정보활용동의ID
        },
        stndKeyVal: 'start',
        // 더보기 key
        stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
        // 페이지 카운트
        pageRowCnt: '20',
        // 인피니트 바인딩 리스트VO
        cmmktCnsntHstrListVO: [],
        // 인피니트 헤더 필수세팅
        headers: [],
        // 더보기 기능
        isLoading: false,
        selected: []
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    props: {
      infoPrcusCnsntId: String
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      this.fn_Init()
    },
    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_Init
      * 설명       : 초기 데이터 셋팅
      ******************************************************************************/
      fn_Init () {
        this.privateId = this.$props.infoPrcusCnsntId
        this.fn_Submit()
      },

      /******************************************************************************
      * Function명 : fn_Submit
      * 설명       : 마케팅동의 이력 조회
      ******************************************************************************/
      fn_Submit () {
        this.stndKeyVal = 'start' // 더보기 최초조회
        this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}] // 초기로드 or 조회버튼시
        this.cmmktCnsntHstrListVO = [] // 인피니트 바인딩 데이터
        this.fn_DataSet() // 조회 파라미터 세팅
        this.fn_GetService() // 서비스 호출
      },

        /******************************************************************************
        * Function명 : fn_DataSet
        * 설명       : 조회전 데이터 파라미터 세팅
      ******************************************************************************/
      fn_DataSet () {
        this.lv_inputParam.infoPrcusCnsntId = this.privateId
        this.lv_inputParam.mktCnsntYn = 'Y'
      },

      /******************************************************************************
        * Function명 : fn_GetService
        * 설명       : 서비스 호출(목록 조회)
      ******************************************************************************/
      fn_GetService () {
        if (this.stndKeyVal === 'end') return
        const trnstId = 'txTSSCM11S5'//  목록 조회
        const auth = 'S'

        let lv_Vm = this
        let pParams = this.lv_inputParam
        let confirm = this.getStore('confirm')

        this.isLoading = true
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
              let responseList = response.body.cmmktCnsntHstrListVO
              if (responseList !== null) {
                if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                  for (let i = 0; i < responseList.length; i++) {
                    lv_Vm.cmmktCnsntHstrListVO.push(responseList[i])
                  }
                } else { // 1페이지
                  lv_Vm.cmmktCnsntHstrListVO = responseList
                }
                if ( lv_Vm.cmmktCnsntHstrListVO.length > 5 ) { // EP와 동일하게 최근 5건만
                  lv_Vm.cmmktCnsntHstrListVO.splice(5)
                }
                // 조회 총 카운트
                lv_Vm.totCnt = lv_Vm.cmmktCnsntHstrListVO.length
                // 더보기 키값
                lv_Vm.stndKeyList = response.trnstComm.stndKeyList
                if (lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
                // 다음페이지 데이터 존재안함(마지막 페이지)
                  lv_Vm.stndKeyVal = 'end'
                } else {
                // 다음페이지 데이터 존재함
                  lv_Vm.stndKeyVal = 'more'
                }
              }
            } else {
              confirm.dispatch('ADD', response.msgComm.msgDesc)
            }
            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /******************************************************************************
        * Function명 : fn_LoadingData
        * 설명       : 인피니트 로딩데이터
      ******************************************************************************/
      fn_LoadingData () {
        this.fn_GetService()
      },

      /******************************************************************************
        * Function명 : fn_confirmClick
        * 설명       : 팝업 닫기
      ******************************************************************************/
      fn_ConfirmClick () {
        this.$emit('onPopupClose')
      },

      /*********************************************************
      * Function명 : fn_Yymmdd
      * 설명       : Date function
     *********************************************************/
      fn_Yymmdd (dateStr) {
        let yy = dateStr.substr(0, 2)
        let month = dateStr.substr(2, 2)
        let day = dateStr.substr(4, 2)
        let str = yy + '-' + month + '-' + day
        return str
      }
    }
  };
</script>